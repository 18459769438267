import React from 'react';
import styled from 'styled-components';
import { useAutoAnimate } from '@formkit/auto-animate/react';

const StyledContainer = styled.div`
  position: relative;
`;

export const MODALS_CONTAINER_ID = 'modals';
export const ModalsContainer = () => {
  const [containerRef] = useAutoAnimate();

  return <StyledContainer id={MODALS_CONTAINER_ID} ref={containerRef} />;
};
