/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';
/* Components */
import { ModalsContainer } from '~/src/ui/Modal';
import { SVGDefs } from '~/src/components/Layout/SiteLayout/components/SVGDefs';
import { CommonLayout } from '../CommonLayout';
import { Navbar } from '../../Navbar';
import { Footer } from '../../Footer';

/* Utils */

/* Types */

/* Styles */

const Main = styled.div(
  ({ theme: { isDark } }) => css`
    ${isDark &&
    css`
      background: #000;
    `}
  `
);

/* Component */
export const SiteLayout = ({ className, meta, pathName, children, disableRouter, isDark }) => {
  return (
    <CommonLayout
      className={className}
      meta={meta}
      pathName={pathName}
      disableRouter={disableRouter}
      isDark={isDark}
    >
      <SVGDefs />
      <Navbar />
      <Main>{children}</Main>
      <Footer isDark={isDark} />
      <ModalsContainer />
    </CommonLayout>
  );
};

/* default props */
SiteLayout.defaultProps = {};
