import React from 'react';

export const SVGDefs = () => (
  <svg style={{ display: 'none' }}>
    <defs>
      <path
        id="checkmark"
        d="M4.7998 9L7.7998 12L13.2998 6.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <g id="circle-checkmark">
        <rect width="24" height="24" rx="12" fill="currentColor" fillOpacity="0.2" />
        <path d="M7 11.5891L10.5714 15.2002L17 8.7002" stroke="currentColor" strokeWidth="2" />
      </g>
    </defs>
  </svg>
);
