import styled, { css } from 'styled-components';
import Image from '@uc-common/image';

export const ModalViewport = styled.div`
  position: fixed;
  inset: 0;
  z-index: 200;
  padding: 0;
`;
export const ModalBackdrop = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    padding: 5px;
    overflow: auto;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-items: center;

    ${tablet()} {
      padding: 50px;
    }

    ${laptop()} {
      padding: 100px;
    }
  `
);

export const ModalContainer = styled.dialog(
  ({ theme: { tablet } }) => css`
    display: block;
    position: relative;
    padding: 24px 20px 40px;
    height: 100%;
    background-color: #111113;
    border: 1px solid #21262f;
    border-radius: 16px;
    box-sizing: border-box;

    ${tablet()} {
      height: auto;
      padding: 44px;
      width: 460px;
    }
  `
);

export const ModalCloseButton = styled.button(
  ({ theme: { tablet } }) => css`
    position: absolute;
    top: 20px;
    right: 20px;
    height: 32px;
    width: 32px;
    box-sizing: border-box;
    cursor: pointer;
    color: #596570;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #ffffff;
    }

    ${tablet()} {
      top: 40px;
      right: 40px;
    }
  `
);

export const ModalIcon = styled(Image)`
  height: 40px;
  width: 40px;
  margin-bottom: 20px;
`;

export const ModalTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  display: block;
`;

export const ModalDescription = styled.p`
  font-size: 15px;
  color: #bbc4d2;
  margin-top: 15px;
  width: 100%;
  line-height: 1.6;
`;
